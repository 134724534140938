import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as V from "yup";

import { DckSelectors, DckActionCreators, DckActionTypes } from "../../redux";
import AuthExternalForm from "./AuthExternalForm";
import AuthForm from "./AuthForm";
import { styles } from "./styles";

class PasswordChangeComponent extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    changePasswordProcessRunning: PropTypes.any,
    changePasswordProcessFailed: PropTypes.any,
    forceChangePassword: PropTypes.any,
    resetProcessForceChangePassword: PropTypes.any,
    ensureChangePasswordUserIsSet: PropTypes.any
  };

  componentDidMount() {
    this.props.resetProcessForceChangePassword();
    this.props.ensureChangePasswordUserIsSet();
  }

  render() {
    const {
      changePasswordProcessRunning,
      changePasswordProcessFailed,
      forceChangePassword
    } = this.props;

    const schema = V.object({
      newPassword: V.string("").label("Password").required().min(6).max(256),
      confirmPassword: V.string("Confirm your password")
        .required("Re-enter your password")
        .oneOf([V.ref("newPassword")], "Password does not match")
    });

    const initValues = {
      newPassword: "",
      confirmPassword: ""
    };

    const fields = {
      newPassword: {
        label: "New password",
        type: "password"
      },
      confirmPassword: {
        label: "Password confirmation",
        type: "password"
      }
    };

    return (
      <AuthExternalForm
        loading={changePasswordProcessRunning}
        failed={changePasswordProcessFailed}
        errorMessage={"Failed to change password! Please check your data."}
      >
        <Formik
          validationSchema={schema}
          initialValues={initValues}
          onSubmit={values => {
            forceChangePassword(values.newPassword);
          }}
        >
          {props => (
            <AuthForm
              {...props}
              loadingProcess={changePasswordProcessRunning}
              fields={fields}
              buttonText="Change Password"
            />
          )}
        </Formik>
      </AuthExternalForm>
    );
  }
}

const mapStateToProps = state => {
  const mapping = {
    changePasswordProcessRunning: DckSelectors.selectProcessRunning(
      state,
      DckActionTypes.FORCE_CHANGE_PASSWORD
    ),
    changePasswordProcessFailed: DckSelectors.selectProcessFailed(
      state,
      DckActionTypes.FORCE_CHANGE_PASSWORD
    )
  };
  return mapping;
};

const mapDispatchToProps = dispatch => {
  const mapping = {
    forceChangePassword: password =>
      dispatch(DckActionCreators.forceChangePassword(password)),
    ensureChangePasswordUserIsSet: () =>
      dispatch(DckActionCreators.ensureChangePasswordUserIsSet()),
    resetProcessForceChangePassword: () =>
      dispatch(
        DckActionCreators.asyncProcessReset(
          DckActionTypes.FORCE_CHANGE_PASSWORD
        )
      )
  };
  return mapping;
};

export const PasswordChange = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PasswordChangeComponent));
