import { useEffect } from "react";

import { Redirect } from "react-router";

import { ActionCreators, Router } from "srx-shared";
import { InviteAccept } from "srx-shared/mui";

import { store } from "@redux/store";
const RedirectSignIn = () => <Redirect to="/sign-in" />;
const RedirectPortal = () => {
  useEffect(() => store.dispatch(ActionCreators.checkRedirect()));
  return null;
};
export const routes = [
  ...Router.authRoutes,
  { path: "/", exact: true, component: RedirectSignIn },
  { path: "/email/accept/:token", component: InviteAccept },
  {
    path: "*",
    component: RedirectPortal
  }
];
