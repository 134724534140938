import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as V from "yup";

import { DckSelectors, DckActionCreators, DckActionTypes } from "../../redux";
import AuthExternalForm from "./AuthExternalForm";
import AuthForm from "./AuthForm";
import { styles } from "./styles";

class ForgotPasswordComponent extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    forgotPasswordProcessRunning: PropTypes.any,
    forgotPasswordProcessFailed: PropTypes.any,
    forgotPassword: PropTypes.any,
    resetProcessForgotPassword: PropTypes.any
  };

  componentDidMount() {
    this.props.resetProcessForgotPassword();
  }

  render() {
    const {
      forgotPasswordProcessRunning,
      forgotPasswordProcessFailed,
      forgotPassword
    } = this.props;

    const schema = V.object({
      email: V.string("Enter your email")
        .label("Email")
        .email()
        .required()
        .max(128)
    });

    const initValues = {
      email: ""
    };

    const fields = {
      email: {
        label: "Email address",
        type: "email"
      }
    };

    return (
      <AuthExternalForm
        loading={forgotPasswordProcessRunning}
        failed={forgotPasswordProcessFailed}
        errorMessage="An error occurred during this operation. Please try again or update the browser page. If it is not the first time you see this message, please contact the administrator."
      >
        <Formik
          validationSchema={schema}
          initialValues={initValues}
          onSubmit={values => forgotPassword(values.email)}
        >
          {props => (
            <AuthForm
              {...props}
              loadingProcess={forgotPasswordProcessRunning}
              fields={fields}
              buttonText="Reset Password"
              link="/sign-in"
              linkText="Go back"
            />
          )}
        </Formik>
      </AuthExternalForm>
    );
  }
}

const mapStateToProps = state => {
  const mapping = {
    forgotPasswordProcessRunning: DckSelectors.selectProcessRunning(
      state,
      DckActionTypes.RESET_PASSWORD
    ),
    forgotPasswordProcessFailed: DckSelectors.selectProcessFailed(
      state,
      DckActionTypes.RESET_PASSWORD
    )
  };

  return mapping;
};

const mapDispatchToProps = dispatch => {
  return {
    forgotPassword: email => dispatch(DckActionCreators.forgotPassword(email)),
    resetProcessForgotPassword: () =>
      dispatch(
        DckActionCreators.asyncProcessReset(DckActionTypes.RESET_PASSWORD)
      )
  };
};

export const ForgotPassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ForgotPasswordComponent));
