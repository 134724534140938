import React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { ItemTypeApplication } from "../config";
import {
  SignIn,
  SignOut,
  ForgotPassword,
  ForgotPasswordSend,
  ForgotPasswordSuccess,
  PasswordChange,
  PasswordReset
} from "../mui/AuthPage";
import { getItemData } from "../redux";
import { getAllowedEntries } from "../utils";

export const authRoutes = [
  {
    path: "/sign-in",
    exact: true,
    component: SignIn
  },
  {
    path: "/sign-out",
    exact: true,
    component: SignOut
  },
  {
    path: "/forgot-password",
    exact: true,
    component: ForgotPassword
  },
  {
    path: "/forgot-password/send/:email",
    exact: true,
    component: ForgotPasswordSend
  },
  {
    path: "/forgot-password/success",
    exact: true,
    component: ForgotPasswordSuccess
  },
  {
    path: "/change-password",
    exact: true,
    component: PasswordChange
  },
  {
    path: "/reset-password/confirm/:userEmail/:verificationCode?",
    exact: true,
    component: PasswordReset
  }
];

export const normalizePath = path => String(path || "").replace(/\/+/, "/");

Routes.propTypes = {
  basePath: PropTypes.string,
  items: PropTypes.array
};
export function Routes(props) {
  const { items, basePath = "" } = props;

  const acls = useSelector(state =>
    getItemData(state, ItemTypeApplication, "acls")
  );

  return (
    <Switch>
      {getAllowedEntries(acls, items).map((route, index) => {
        const Component = route.component;
        if (!Component) return null;

        const path = normalizePath(`${basePath}/${route.path}`);
        const key = `${index}-${path}`;
        const { subroutes, exact } = route;

        return (
          <Route
            key={key}
            path={path}
            exact={Boolean(exact)}
            render={routeProps => {
              global.currentAcl = route.acls;
              return (
                <Component {...routeProps}>
                  {subroutes && <Routes items={subroutes} basePath={path} />}
                </Component>
              );
            }}
          />
        );
      })}
    </Switch>
  );
}
