import { ItemTypeApplication } from "../config";

export const ActionTypes = {
  CHECK_BUILD_VERSION: "CHECK_BUILD_VERSION",
  CHECK_REDIRECT: "CHECK_REDIRECT",
  SET_ITEM_DATA: "SET_ITEM_DATA",
  SET_DISPLAY_PARAM: "SET_DISPLAY_PARAM",
  ACCEPT_EMAIL_INVITE: "ACCEPT_EMAIL_INVITE",
  SHOW_LOADING_INDICATOR: "SHOW_LOADING_INDICATOR",
  HIDE_LOADING_INDICATOR: "HIDE_LOADING_INDICATOR",
  RESET_LOADING_INDICATOR: "RESET_LOADING_INDICATOR",
  SET_SELECTED_FILTER: "SET_SELECTED_FILTER",
  SHOW_EDIT_MODAL: "SHOW_EDIT_MODAL",
  HIDE_EDIT_MODAL: "HIDE_EDIT_MODAL",
  RESET_EDIT_MODAL: "RESET_EDIT_MODAL",
  PUSH_NOTIFICATION: "ENQUEUE_SNACKBAR",
  REMOVE_NOTIFICATION: "REMOVE_SNACKBAR",
  HIDE_NOTIFICATIONS: "HIDE_SNACKBARS",
  IMPORT_COMPLETED: "IMPORT_COMPLETED",
  EXPORT_COMPLETED: "EXPORT_COMPLETED",
  EXPORT_ITEMS: "EXPORT_ITEMS",
  DOWNLOAD_DETAILS: "DOWNLOAD_DETAILS",
  DOWNLOAD_ATTACHMENT: "DOWNLOAD_ATTACHMENT",
  INLINE_UPDATE: "INLINE_UPDATE",
  SELECT_ITEM: "SELECT_ITEM",
  SELECT_ITEMS: "SELECT_ITEMS",
  SET_ALL_SELECTED: "SET_ALL_SELECTED",
  UNDO_ACTION: "UNDO_ACTION",
  UNDO_RESET: "UNDO_RESET",
  UNDO_PUSH: "UNDO_PUSH",
  UNDO_POP: "UNDO_POP"
};
const actionSetItemData = (itemType, field, data) => ({
  type: ActionTypes.SET_ITEM_DATA,
  itemType,
  field,
  data
});

const actionSetDisplayParam = (itemType, field, data) => ({
  type: ActionTypes.SET_DISPLAY_PARAM,
  itemType,
  field,
  data
});

export const ActionCreators = {
  checkBuildVersion: () => ({
    type: ActionTypes.CHECK_BUILD_VERSION
  }),

  checkRedirect: () => ({
    type: ActionTypes.CHECK_REDIRECT
  }),

  pushNotification: notification => ({
    type: ActionTypes.PUSH_NOTIFICATION,
    notification: {
      key: new Date().getTime() + Math.random(),
      ...notification
    }
  }),

  removeNotification: key => ({
    type: ActionTypes.REMOVE_NOTIFICATION,
    key
  }),

  importCompleted: (itemType, response) => ({
    type: ActionTypes.IMPORT_COMPLETED,
    itemType,
    response
  }),

  exportCompleted: itemType => ({
    type: ActionTypes.EXPORT_COMPLETED,
    itemType
  }),

  hideNotifications: () => ({
    type: ActionTypes.HIDE_NOTIFICATIONS
  }),

  downloadDetails: data => ({
    type: ActionTypes.DOWNLOAD_DETAILS,
    data
  }),

  downloadAttachment: (fileName, shiptoId) => ({
    type: ActionTypes.DOWNLOAD_ATTACHMENT,
    fileName,
    shiptoId
  }),

  inlineUpdateItem: (itemType, data) => ({
    type: ActionTypes.INLINE_UPDATE,
    itemType,
    data
  }),
  undoAction: (itemType, data) => ({
    type: ActionTypes.UNDO_ACTION,
    itemType,
    data
  }),
  undoReset: itemType => ({
    type: ActionTypes.UNDO_RESET,
    itemType
  }),
  undoPush: (itemType, data) => ({
    type: ActionTypes.UNDO_PUSH,
    itemType,
    data
  }),
  undoPop: itemType => ({
    type: ActionTypes.UNDO_POP,
    itemType
  }),

  setItemData: actionSetItemData,

  setDisplayParam: actionSetDisplayParam,

  setSelectedFilter: (field, selected) => ({
    type: ActionTypes.SET_SELECTED_FILTER,
    field,
    selected
  }),

  setSelectedItems: (itemType, selectedItems) =>
    actionSetItemData(itemType, "selectedItems", selectedItems),

  setAllSelected: (itemType, allSelected) =>
    actionSetItemData(itemType, "allSelected", allSelected),

  exportItems: (itemType, data) => ({
    type: ActionTypes.EXPORT_ITEMS,
    itemType,
    data
  }),

  setValidateStatus: (itemType, validateStatus) =>
    actionSetItemData(itemType, "validateStatus", validateStatus),

  setTotalPages: (itemType, totalPages) =>
    actionSetItemData(itemType, "totalPages", totalPages),

  setTotalEntities: (itemType, totalEntities) =>
    actionSetItemData(itemType, "totalEntities", totalEntities),

  setCurrentPage: (itemType, currentPage) =>
    actionSetItemData(itemType, "currentPage", currentPage),

  setPageSize: (itemType, pageSize) =>
    actionSetItemData(itemType, "pageSize", pageSize),

  setHasNextPage: (itemType, hasNext) =>
    actionSetItemData(itemType, "hasNext", hasNext),

  setPageTitle: title =>
    actionSetItemData(ItemTypeApplication, "pageTitle", title),

  setCurrentLayout: (itemType, layout) =>
    actionSetDisplayParam(itemType, "currentLayout", layout),

  setAllLayouts: (itemType, layouts) =>
    actionSetDisplayParam(itemType, "layouts", layouts),

  setAllTableLayouts: (itemType, layouts) =>
    actionSetDisplayParam(itemType, "tableLayouts", layouts),

  acceptEmailInvite: token => ({
    type: ActionTypes.ACCEPT_EMAIL_INVITE,
    token
  }),

  showLoadingIndicator: () => ({
    type: ActionTypes.SHOW_LOADING_INDICATOR
  }),
  hideLoadingIndicator: () => ({
    type: ActionTypes.HIDE_LOADING_INDICATOR
  }),
  resetLoadingIndicator: () => ({
    type: ActionTypes.RESET_LOADING_INDICATOR
  }),

  showEditModal: itemType => ({
    type: ActionTypes.SHOW_EDIT_MODAL,
    itemType
  }),
  hideEditModal: itemType => ({
    type: ActionTypes.HIDE_EDIT_MODAL,
    itemType
  }),
  resetEditModal: itemType => ({
    type: ActionTypes.RESET_EDIT_MODAL,
    itemType
  })
};
