import { ApiCallException } from "../exceptions/ApiCallException";
import { DckActionTypes } from "../redux";

export const start = processCode => {
  return {
    type: DckActionTypes.ASYNC_PROCESS_START,
    processCode
  };
};

export const reset = processCode => {
  return {
    type: DckActionTypes.ASYNC_PROCESS_RESET,
    processCode
  };
};

export const stop = (processCode, description) => {
  return {
    type: DckActionTypes.ASYNC_PROCESS_STOP,
    processCode,
    result: {
      success: true,
      description
    }
  };
};

const warn = msg => {
  if (process.env.NODE_ENV === "development") {
    console.warn(msg);
  }
};

export const fail = (processCode, error) => {
  const result = { success: false };
  if (error) {
    if (error.reason) {
      result.reason = error.reason;
      warn(`${processCode}: ${result.reason}`);
    } else if (error instanceof ApiCallException) {
      result.error = error;
      warn(`${processCode}: ${error.message} (SessionID: ${error.sessionId})`);
    } else {
      result.error = error.message || error;
      warn(`${processCode}: ${JSON.stringify(result.error)}`);
    }
  }
  return {
    type: DckActionTypes.ASYNC_PROCESS_STOP,
    processCode,
    result
  };
};
