import _get from "lodash/get";
import _set from "lodash/set";
import { createSelector } from "reselect";

import { DckSelectors, procName } from "../redux";

const poolOfSelectors = {};

export const filters = itemType => state => {
  return DckSelectors.selectItemSearchFilters(state, itemType);
};

const sortingSelector = createSelector(
  (state, itemType) =>
    DckSelectors.getItemData(state, itemType, "sortingOptions"),
  data => (data ? data.toJS() : [])
);

export const sorting = itemType => state => sortingSelector(state, itemType);

export const selectedFilters = createSelector(
  state => state.app?.selectedFilters,
  data => data || {}
);

export const getItemData = (state, itemType, field) =>
  (itemType && state.app && state.app[field] && state.app[field][itemType]) ||
  null;

export const getDisplayParam = (state, itemType, field) =>
  (itemType &&
    state.display &&
    state.display[field] &&
    state.display[field][itemType]) ||
  null;

export const getValidateStatus = (state, itemType) =>
  getItemData(state, itemType, "validateStatus");

export const getTotalPages = (state, itemType) =>
  getItemData(state, itemType, "totalPages");

export const getTotalEntities = (state, itemType) =>
  getItemData(state, itemType, "totalEntities");

export const getCurrentPage = (state, itemType) =>
  getItemData(state, itemType, "currentPage");

export const getPageSize = (state, itemType) =>
  getItemData(state, itemType, "pageSize");

export const getHasNextPage = (state, itemType) =>
  getItemData(state, itemType, "hasNext");

export const getSelectedItems = (state, itemType) =>
  getItemData(state, itemType, "selectedItems");

export const getSelectedItemIds = (state, itemType) => {
  const selectedItems = getSelectedItems(state, itemType);
  const items = DckSelectors.selectAllItems(state, itemType);
  if (!selectedItems || !items) return [];
  const ids = items.filter(el => selectedItems.has(el.id));
  return ids;
};

export const getCurrentLayout = createSelector(
  (state, itemType) => getDisplayParam(state, itemType, "currentLayout"),
  data => data || {}
);

export const getAllLayouts = createSelector(
  (state, itemType) => getDisplayParam(state, itemType, "layouts"),
  data => data || []
);

export const getAllTableLayouts = createSelector(
  (state, itemType) => getDisplayParam(state, itemType, "tableLayouts"),
  data => data || []
);

export const getAllSelected = (state, itemType) =>
  getItemData(state, itemType, "allSelected");

export const loadingIndicator = state =>
  Boolean(state.app && Number(state.app.loadingIndicator));

export const modalState = (state, itemType) =>
  (itemType && state.app && state.app.modal && state.app.modal[itemType]) ||
  null;

export const undoEmpty = (state, itemType) =>
  itemType && state.undo && state.undo[itemType]
    ? state.undo[itemType].isEmpty()
    : true;

export const undoDisabled = (state, itemType) =>
  undoEmpty(state, itemType) || loadingIndicator(state, itemType);

export const undoTop = (state, itemType) =>
  itemType && state.undo && state.undo[itemType]
    ? state.undo[itemType].getTop()
    : void 0;

export const getUndoLength = (state, itemType) =>
  itemType && state.undo && state.undo[itemType]
    ? state.undo[itemType].getLength()
    : 0;

export const selectAllItems = (state, itemType) => {
  let selector = _get(poolOfSelectors, ["selectAllItems", itemType]);
  if (!selector) {
    selector = createSelector(
      state => DckSelectors.getItemData(state, itemType, "items"),
      data => (data ? data.toJS() : [])
    );
    _set(poolOfSelectors, ["selectAllItems", itemType], selector);
  }
  return selector(state);
};

export const selectActiveItemId = (state, itemType) =>
  state.dck.items.getIn([itemType, "active"]);

export const selectActiveItem = (state, itemType) => {
  let selector = _get(poolOfSelectors, ["selectActiveItem", itemType]);
  if (!selector) {
    selector = createSelector(
      state => selectActiveItemId(state, itemType),
      state => selectAllItems(state, itemType),
      (activeItemId, items) => items.find(x => x.id === activeItemId) || null
    );
    _set(poolOfSelectors, ["selectActiveItem", itemType], selector);
  }
  return selector(state);
};

export const selectItemSearchFilters = (state, itemType) => {
  let selector = _get(poolOfSelectors, ["selectItemSearchFilters", itemType]);
  if (!selector) {
    selector = createSelector(
      state => state.dck.items.getIn([itemType, "filters"]),
      data => (data ? data.toJS() : {})
    );
    _set(poolOfSelectors, ["selectItemSearchFilters", itemType], selector);
  }
  return selector(state);
};

export const getFilterValues = (state, itemType, name) => {
  let selector = _get(poolOfSelectors, ["getFilterValues", itemType, name]);
  if (!selector) {
    selector = createSelector(
      state => state.dck.items.getIn([itemType, "filters", name]),
      data => data?.toJS() || []
    );
    _set(poolOfSelectors, ["getFilterValues", itemType, name], selector);
  }
  return selector(state);
};

export const getProcErrorMessage = (state, itemType, action) => {
  const process = DckSelectors.selectProcess(state, procName(itemType, action));
  const error = process?.result?.error;
  if (!error) return null;
  return error.message || error;
};
