import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import { ActionCreators, ActionTypes } from "../../actions";
import { DckSelectors } from "../../redux";
import AuthExternalForm from "./AuthExternalForm";
import { styles } from "./styles";

class InviteAcceptComponent extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object,
    acceptInvite: PropTypes.func,
    acceptInviteProcess: PropTypes.object,
    acceptInviteProcessRunning: PropTypes.bool,
    acceptInviteProcessFailed: PropTypes.bool,
    history: PropTypes.object.isRequired
  };

  state = {
    seconds: 10,
    timer: null
  };

  componentDidMount() {
    const {
      match: { params: { token } = {} }
    } = this.props;

    this.props.acceptInvite(encodeURIComponent(token || ""));
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.acceptInviteProcessRunning &&
      !nextProps.acceptInviteProcessRunning &&
      !nextProps.acceptInviteProcessFailed
    ) {
      const timer = setInterval(() => {
        if (this.state.seconds !== 0) {
          this.setState({ seconds: this.state.seconds - 1 });
        } else {
          clearInterval(this.state.timer);
          this.props.history.push("/sign-in");
        }
      }, 1000);
      this.setState({ timer });
    }
    return true;
  }

  render() {
    const {
      classes,
      acceptInviteProcessFailed,
      acceptInviteProcessRunning,
      acceptInviteProcess
    } = this.props;

    return (
      <AuthExternalForm
        loading={acceptInviteProcessRunning}
        failed={acceptInviteProcessFailed}
        errorMessage={
          acceptInviteProcessFailed && acceptInviteProcess.result.error
        }
      >
        <div className={classes.message}>
          {acceptInviteProcessFailed ? (
            <Link to="/sign-in" className={classes.link}>
              Go to login page
            </Link>
          ) : acceptInviteProcessRunning ? (
            <h3>Please wait...</h3>
          ) : (
            <div>
              <h3>Your invite was successfully accepted</h3>
              <div>
                Please check your email. You will be redirected to main page in{" "}
                {this.state.seconds} seconds.
              </div>
              <Link to="/sign-in" className={classes.link}>
                Go to login page
              </Link>
            </div>
          )}
        </div>
      </AuthExternalForm>
    );
  }
}

const mapStateToProps = state => {
  const mapping = {
    acceptInviteProcess: DckSelectors.selectProcess(
      state,
      ActionTypes.ACCEPT_EMAIL_INVITE
    ),
    acceptInviteProcessRunning: DckSelectors.selectProcessRunning(
      state,
      ActionTypes.ACCEPT_EMAIL_INVITE
    ),
    acceptInviteProcessFailed: DckSelectors.selectProcessFailed(
      state,
      ActionTypes.ACCEPT_EMAIL_INVITE
    )
  };
  return mapping;
};

const mapDispatchToProps = {
  acceptInvite: token => ActionCreators.acceptEmailInvite(token)
};

export const InviteAccept = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(InviteAcceptComponent))
);
