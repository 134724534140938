function ApiCallException(message, sessionId, response) {
  this.name = "ApiCallException";
  this.message = message;
  this.sessionId = sessionId;
  this.response = response;
  this.stack = new Error().stack;
}
ApiCallException.prototype = Object.create(Error.prototype);
ApiCallException.prototype.constructor = ApiCallException;
ApiCallException.prototype.toString = function () {
  return this.message;
};

export { ApiCallException };
