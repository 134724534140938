import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import AuthExternalForm from "./AuthExternalForm";
import { styles } from "./styles";

class ForgotPasswordSendComponent extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object
  };

  render() {
    const {
      classes,
      match: { params }
    } = this.props;

    return (
      <AuthExternalForm>
        <div className={classes.message}>
          <h2>Please check your inbox</h2>
          <div>
            In the case if entered email is present in the system, a password
            reset link will be sent to your email {params.email}.
          </div>
          <div>Email should arrive within 5 minutes</div>
          <Link to="/sign-in" className={classes.link}>
            Go back
          </Link>
        </div>
      </AuthExternalForm>
    );
  }
}

export const ForgotPasswordSend = withStyles(styles)(
  ForgotPasswordSendComponent
);
