const ERROR = {
  variant: "error"
};
const WARN = {
  variant: "warning"
};
const SUCCESS = {
  variant: "success"
};

export const NotificationCreators = {
  successOperation: message => ({
    message,
    options: SUCCESS
  }),
  warningMessage: message => ({
    message,
    options: WARN
  }),
  errorMessage: message => ({
    message,
    options: ERROR
  }),
  errorApiCall: ({ message, sessionId }) => ({
    message: (
      <>
        {message}
        {sessionId && (
          <>
            <br />
            (Session ID: {sessionId})
          </>
        )}
      </>
    ),
    options: ERROR
  }),
  errorImport: response => ({
    message: (
      <div>
        <p>
          <strong>Operation failed!</strong>
        </p>
        {response.description}
        {response.description.length === 1 && (
          <p>
            {`${response.description[0].lineNumber} : ${response.description[0].errorMessage}`}
          </p>
        )}
      </div>
    ),
    options: ERROR
  }),
  partialImport: response => ({
    message: (
      <div>
        <p>
          <strong>Partially imported</strong>
        </p>
        {response.description}
        {response.description.length === 1 && (
          <p>
            {`${response.description[0].lineNumber} : ${response.description[0].errorMessage}`}
          </p>
        )}
      </div>
    ),
    options: WARN
  }),
  successImport: response => ({
    message: (
      <div>
        <p>
          <strong>Successfully imported</strong>
        </p>
        <p>{response.description !== "exported" && response.description}</p>
      </div>
    ),
    options: SUCCESS
  }),

  emailLabels: () => ({
    message: (
      <p>
        <strong>PDF file with generated labels will be sent by email</strong>
      </p>
    ),
    options: SUCCESS
  }),

  emailImportStatus: () => ({
    message: (
      <p>
        <strong>
          Import process takes too long... Finish status will be sent by email
        </strong>
      </p>
    ),
    options: WARN
  })
};
