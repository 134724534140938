import React, { Component } from "react";

import { CssBaseline, Paper, LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import PropTypes from "prop-types";

import { PATH_TO_ASSETS } from "../../config";
import { styles } from "./styles";

class AuthExternalForm extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    failed: PropTypes.bool,
    errorMessage: PropTypes.any
  };

  render() {
    const { classes, children, loading, failed, errorMessage } = this.props;
    const error =
      errorMessage && typeof errorMessage === "object"
        ? errorMessage.message
        : errorMessage;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          {loading ? (
            <LinearProgress className={classes.progress} />
          ) : (
            <div className={classes.line} />
          )}
          <div className={classes.body}>
            <img
              src={`${PATH_TO_ASSETS}/srx-logo.svg`}
              width="240"
              alt={"srx-logo"}
            />
            {children}
            {failed && (
              <div className={classes.errorMessage}>
                <ErrorIcon color={"error"} />
                <span>{error || ""}</span>
              </div>
            )}
          </div>
        </Paper>
      </main>
    );
  }
}

export default withStyles(styles)(AuthExternalForm);
