import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import * as V from "yup";

import { DckSelectors, DckActionCreators, DckActionTypes } from "../../redux";
import AuthExternalForm from "./AuthExternalForm";
import AuthForm from "./AuthForm";
import { styles } from "./styles";

class PasswordResetComponent extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    resetPasswordProcessRunning: PropTypes.any,
    resetPasswordProcessFailed: PropTypes.any,
    confirmForgotPassword: PropTypes.any,
    resetPasswordProcess: PropTypes.any,
    resetProcessConfirmForgotPassword: PropTypes.any,
    location: PropTypes.object,
    match: PropTypes.object
  };

  componentDidMount() {
    const {
      match: {
        params: { userEmail }
      },
      resetProcessConfirmForgotPassword,
      location
    } = this.props;

    resetProcessConfirmForgotPassword();
    if (!userEmail) location.push("/sign-in");
  }

  render() {
    const {
      resetPasswordProcessRunning,
      resetPasswordProcessFailed,
      confirmForgotPassword,
      resetPasswordProcess,
      match: {
        params: { verificationCode, userEmail }
      }
    } = this.props;

    const scheme = {
      newPassword: V.string("").label("Password").required().min(6).max(256),
      confirmPassword: V.string("Confirm your password")
        .required("Re-enter your password")
        .oneOf([V.ref("newPassword")], "Password does not match")
    };

    const initValues = {
      newPassword: "",
      confirmPassword: ""
    };

    const fields = {
      newPassword: {
        label: "New password",
        type: "password"
      },
      confirmPassword: {
        label: "Password confirmation",
        type: "password"
      }
    };

    if (!verificationCode) {
      scheme["verificationCode"] = V.string("Enter verification code").required(
        "Verification code should not be empty"
      );
      initValues["verificationCode"] = "";
      fields["verificationCode"] = {
        label: "Verification code",
        type: "text"
      };
    }

    return (
      <AuthExternalForm
        loading={resetPasswordProcessRunning}
        failed={resetPasswordProcessFailed}
        errorMessage={
          (resetPasswordProcessFailed && resetPasswordProcess.result.error) ||
          "Please check your data."
        }
      >
        <Formik
          validationSchema={V.object(scheme)}
          initialValues={initValues}
          onSubmit={values => {
            confirmForgotPassword(
              userEmail,
              verificationCode || values.verificationCode,
              values.newPassword
            );
          }}
        >
          {props => (
            <AuthForm
              {...props}
              loadingProcess={resetPasswordProcessRunning}
              fields={fields}
              buttonText="Change Password"
              link="/sign-in"
              linkText="Back to sign in"
            />
          )}
        </Formik>
      </AuthExternalForm>
    );
  }
}

const mapStateToProps = state => {
  const mapping = {
    resetPasswordProcess: DckSelectors.selectProcess(
      state,
      DckActionTypes.RESET_PASSWORD_CONFIRMATION
    ),
    resetPasswordProcessRunning: DckSelectors.selectProcessRunning(
      state,
      DckActionTypes.RESET_PASSWORD_CONFIRMATION
    ),
    resetPasswordProcessFailed: DckSelectors.selectProcessFailed(
      state,
      DckActionTypes.RESET_PASSWORD_CONFIRMATION
    )
  };
  return mapping;
};

const mapDispatchToProps = dispatch => {
  const mapping = {
    confirmForgotPassword: (email, verificationCode, password) =>
      dispatch(
        DckActionCreators.confirmForgotPassword(
          email,
          verificationCode,
          password
        )
      ),
    resetProcessConfirmForgotPassword: () =>
      dispatch(
        DckActionCreators.asyncProcessReset(
          DckActionTypes.RESET_PASSWORD_CONFIRMATION
        )
      )
  };
  return mapping;
};

export const PasswordReset = compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(PasswordResetComponent);
