import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router";

import { gtm, gtag, Sentry, Routes, SRX_PORTAL } from "srx-shared";

import { BUILD_VERSION, STAGE } from "./config";
import "./index.css";
import { store, history } from "./redux/store";
import { routes } from "./routes";
import { unregister } from "./serviceWorker";

window.SRX_PLATFORM_VERSION = BUILD_VERSION;
window.SRX_PORTAL = SRX_PORTAL.AUTH;

if (process.env.NODE_ENV === "production") {
  Sentry.init();
  Sentry.setTag("portal-version", BUILD_VERSION);
  Sentry.setTag("stage", STAGE);
  Sentry.setTag("portal", SRX_PORTAL.AUTH);
}

gtm.init(process.env.REACT_APP_GTM_ID);

gtag.init(process.env.REACT_APP_GTAG_ID);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Routes items={routes} />
    </Router>
  </Provider>,
  document.getElementById("root")
);
unregister();
