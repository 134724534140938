import { AWSIoTProvider } from "@aws-amplify/pubsub";
import Amplify from "aws-amplify";

import { AuthStorage } from "./auth-storage";

export function initializeAmplify() {
  const USER_PULL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
  const CLIENT_APP_ID = process.env.REACT_APP_COGNITO_CLIENT_APP_ID;
  const REGION = process.env.REACT_APP_REGION;
  const IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID;
  const MQTT_WSS_URL = process.env.REACT_APP_MQTT_WSS_URL;

  const storage = new AuthStorage({
    domain: window.location.hostname,
    clientId: CLIENT_APP_ID
  });

  const config = {
    userPoolId: USER_PULL_ID,
    userPoolWebClientId: CLIENT_APP_ID,
    storage,
    region: REGION,
    identityPoolId: IDENTITY_POOL_ID
  };

  Amplify.configure({ Auth: config });

  Amplify.addPluggable(
    new AWSIoTProvider({
      aws_pubsub_region: REGION,
      aws_pubsub_endpoint: MQTT_WSS_URL
    })
  );
}
