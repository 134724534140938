import version from "./version.json";

export const BUILD_VERSION = version;

export const STAGE = process.env.REACT_APP_STAGE || "dev";

export const PATH_TO_ASSETS = `${process.env.PUBLIC_URL}/assets`;

export const API_HOST =
  process.env.REACT_APP_API_HOST || `https://api-${STAGE}.storeroomlogix.com`;
