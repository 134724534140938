import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import AuthExternalForm from "./AuthExternalForm";
import { styles } from "./styles";

class ForgotPasswordSuccessComponent extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  render() {
    const { classes } = this.props;
    return (
      <AuthExternalForm>
        <div className={classes.message}>
          <h2>Password was successfully reset</h2>
          <div>You can now login using your new password.</div>
          <Link to="/sign-in" className={classes.link}>
            Go to the login page
          </Link>
        </div>
      </AuthExternalForm>
    );
  }
}

export const ForgotPasswordSuccess = withStyles(styles)(
  ForgotPasswordSuccessComponent
);
