function ResponseTimeException(message) {
  this.name = "ResponseTimeException";
  this.message = message;
  this.stack = new Error().stack;
}
ResponseTimeException.prototype = Object.create(Error.prototype);
ResponseTimeException.prototype.constructor = ResponseTimeException;
ResponseTimeException.prototype.toString = function () {
  return this.message;
};

export { ResponseTimeException };
