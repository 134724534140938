import * as Cookies from "js-cookie";

export class AuthStorage {
  /**
   * Constructs a new CookieStorage object
   * @param {object} data Creation options.
   * @param {string} data.domain Cookies domain (mandatory).
   * @param {string} data.path Cookies path (default: '/')
   * @param {integer} data.expires Cookie expiration (in days, default: 365)
   * @param {boolean} data.secure Cookie secure flag (default: false)
   */
  constructor(data) {
    this.clientId = data.clientId;
    this.cognitoPrefix = `CognitoIdentityServiceProvider.${this.clientId}`;
    this.srxPrefix = `srx.${this.clientId}`;
    this.domain = data.domain;
    if (data.path) {
      this.path = data.path;
    } else {
      this.path = "/";
    }
    if ("expires" in data) {
      this.expires = data.expires;
    } else {
      this.expires = 365;
    }
    this.secure = data.secure || false;
  }

  clearCookiesWithPrefix = prefix => {
    const cookies = Cookies.get();
    Object.keys(cookies).forEach(cookie => {
      if (cookie.startsWith(prefix)) this.remove(cookie);
    });
  };

  clearClientCookies = () => {
    this.clearCookiesWithPrefix(this.cognitoPrefix);
    this.clearCookiesWithPrefix(this.srxPrefix);
  };

  remove = key => {
    return Cookies.remove(key, {
      path: this.path,
      domain: this.domain,
      secure: this.secure
    });
  };

  normalize = key => {
    let [, id, user, suffix] = key.split(".");
    if (user === "LastAuthUser") suffix = user;
    return id ? `srx.${id}.${suffix}` : key;
  };

  normalizeCookies = () => {
    const badCookie = "srx.undefined.undefined";
    if (Cookies.get(badCookie)) this.remove(badCookie);

    const cookies = Cookies.get();
    Object.keys(cookies).forEach(cookie => {
      if (cookie.startsWith(this.cognitoPrefix)) {
        const value = cookies[cookie];
        this.remove(cookie);
        this.setItem(cookie, value);
      }
    });
  };

  getUserData = () => {
    try {
      const userData =
        JSON.parse(Cookies.get(`${this.srxPrefix}.userData`)) || {};
      const firstName = userData.Username;
      const emailAttr = userData.UserAttributes.find(
        attr => attr.Name === "email"
      );
      const email = emailAttr && emailAttr.Value;
      return { firstName, email };
    } catch (e) {
      return null;
    }
  };

  /**
   * This is used to set a specific item in storage
   * @param {string} key - the key for the item
   * @param {object} value - the value
   * @returns {string} value that was set
   */
  setItem(key, value) {
    Cookies.set(this.normalize(key), value, {
      path: this.path,
      expires: this.expires,
      domain: this.domain,
      secure: this.secure
    });
    return Cookies.get(key);
  }

  /**
   * This is used to get a specific key from storage
   * @param {string} key - the key for the item
   * This is used to clear the storage
   * @returns {string} the data item
   */
  getItem(key) {
    this.normalizeCookies();
    return Cookies.get(this.normalize(key));
  }

  /**
   * This is used to remove an item from storage
   * @param {string} key - the key being set
   * @returns {void}
   */
  removeItem(key) {
    this.remove(this.normalize(key));
    return void 0;
  }

  /**
   * This is used to clear the storage
   * @returns {void}
   */
  clear() {
    const cookies = Cookies.get();
    Object.keys(cookies).forEach(cookie => this.remove(cookie));
    return void 0;
  }
}
