import esb from "elastic-builder/src";

export const buildSearchRequest = ({
  filtersConfig = {},
  filters = {},
  sorting = [],
  page = 0,
  pageSize = 0
} = {}) => {
  const isKeyword = field =>
    Boolean(
      filtersConfig[field] && filtersConfig[field].fieldType !== "datetime"
    );

  let query = esb.boolQuery().must(esb.matchAllQuery());
  let queryFields = { ...filters };

  if (queryFields.startDate || queryFields.endDate) {
    let date = esb.rangeQuery("createdAt");
    if (queryFields.startDate) {
      date = date.gte(queryFields.startDate[0]);
      delete queryFields.startDate;
    }
    if (queryFields.endDate) {
      date = date.lte(queryFields.endDate[0]);
      delete queryFields.endDate;
    }
    query = query.filter(date);
  }

  Object.keys(queryFields).forEach(filter => {
    let term = "";
    let method = "must";
    let values = queryFields[filter];
    let value = values[0];
    if (filter === "eventContent.dsku") {
      if (value === "") return;
      term = esb.matchPhraseQuery(filter, value);
    } else if (filter === "eventContent.allocationCodes") {
      if (value === "") return;
      term = esb.matchPhraseQuery(filter, `values:[${value}]`);
      query = query.should(term);
      term = esb.matchPhraseQuery(
        "eventContent.cartItemAllocationCodes.value",
        value
      );
      query = query.should(term);
      query = query.minimumShouldMatch(1);
      return;
    } else if (filter.startsWith("eventContent.") || filter === "result") {
      if (value === "") return;
      term = esb.termsQuery(filter, values);
    } else {
      if (value.startsWith("__NOT__")) {
        value = value.replace("__NOT__", "");
        method = "mustNot";
      } else {
        method = isKeyword(filter) ? "must" : "should";
      }
      term = esb.matchQuery(filter, value);
    }
    query = query[method](term);
  });

  let request = esb.requestBodySearch().query(query);

  if (sorting && sorting.length > 0)
    request = request.sorts(
      sorting.map(field =>
        esb
          .sort(
            `${field.name}${isKeyword(field.name) ? ".keyword" : ""}`,
            field.order
          )
          .missing(field.order === "asc" ? "_first" : "_last")
      )
    );

  if (pageSize) request = request.from(page * pageSize).size(pageSize);

  return request.toJSON();
};
