import { useEffect } from "react";

import { connect } from "react-redux";

import { DckActionCreators } from "../../redux";

// trigger signOut action on mount
function SignOutComponent(props) {
  const { signOut } = props;

  useEffect(() => {
    signOut && signOut();
    return undefined;
  }, [signOut]);

  return null;
}

const mapDispatchToProps = {
  signOut: () => DckActionCreators.signOut()
};

export const SignOut = connect(null, mapDispatchToProps)(SignOutComponent);
