import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { styles } from "./styles";

class AuthFormComponent extends Component {
  static propTypes = {
    fields: PropTypes.object,
    loadingProcess: PropTypes.bool,
    buttonText: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,

    classes: PropTypes.object.isRequired,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldTouched: PropTypes.func,
    isValid: PropTypes.bool,
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object
  };

  onChange = e => {
    const { handleChange, setFieldTouched } = this.props;
    e.persist();
    handleChange(e);
    setFieldTouched && setFieldTouched(e.target.name, true, false);
  };

  handleKeyUp = event => {
    const { handleSubmit, loadingProcess } = this.props;
    event.preventDefault();
    if (event.keyCode === 13 && !loadingProcess) {
      handleSubmit();
    }
  };

  getTextField = (key, name, label, type) => {
    const { classes, errors = {}, touched = {}, values = {} } = this.props;
    return (
      <TextField
        id={name}
        key={key}
        name={name}
        label={label}
        type={type}
        value={values[name] || ""}
        helperText={touched[name] ? errors[name] : ""}
        error={touched[name] && Boolean(errors[name])}
        className={classes.textField}
        InputProps={{
          onKeyUp: this.handleKeyUp,
          classes: {
            root: classes.underline
          }
        }}
        InputLabelProps={{
          classes: {
            root: classes.label,
            focused: classes.focusedLabel
          }
        }}
        onChange={this.onChange}
        required
        fullWidth
      />
    );
  };

  render() {
    const {
      classes,
      handleSubmit,
      loadingProcess,
      isValid,
      fields = {},
      buttonText,
      link,
      linkText
    } = this.props;

    return (
      <form className={classes.form} onSubmit={handleSubmit}>
        {Object.keys(fields).map((f, index) =>
          this.getTextField(index, f, fields[f].label, fields[f].type)
        )}
        {link && (
          <div>
            <Link to={link} className={classes.link}>
              {linkText}
            </Link>
          </div>
        )}
        <Button
          data-testid="auth-button"
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          className={classes.button}
          disabled={loadingProcess || !isValid}
        >
          {buttonText || ""}
        </Button>
      </form>
    );
  }
}

export default withStyles(styles)(AuthFormComponent);
