export const gtag = {
  init(gtagId) {
    if (!gtagId) return;

    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      function gtag() {
        w[l].push(arguments);
      }
      gtag("js", new Date());
      gtag("config", gtagId);
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s);
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtag/js?id=" + i;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", gtagId);
  }
};
