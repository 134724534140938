import { call, put, select } from "redux-saga/effects";

import { ActionCreators } from "../actions";
import * as Selectors from "../actions/selectors";
import { ItemTypeApplication, DEFAULT_PAGE_SIZE } from "../config";
import { DckActionCreators } from "../redux";
import { getSessionData } from "../sagas/account";
import { buildSearchRequest } from "../utils/search";
import { start, reset, stop, fail } from "./helpers";

const globalAny: any = global;

const isEditAction = (method: string): boolean =>
  Object.keys(globalAny.currentAcl || {})[0] !== "configure" &&
  globalAny.currentAction !== "configure" &&
  [
    "add",
    "update",
    "remove",
    "import",
    "export",
    "ACTIVATE_ACCOUNT",
    "UPGRADE_ACCOUNT",
    "INLINE_UPDATE",
    "UNDO_ACTION",
    "VALIDATE",
    "TEST_CONNECTION",
    "GENERATE",
    "GENERATE_NEW",
    "SPLIT"
  ].includes(method);

/* usage example:

function* loadOrdersSaga(action) {
  const proc = new Process.Load(ItemTypes.Order, RestApi.GetOrders);
  yield proc.start();
  try {
    const page = yield proc.page();
    const pageSize = yield proc.pageSize();
    const filters = yield proc.filters();
    const sorting = yield proc.sorting();
    const customerId = yield proc.itemParam("customerId");

    const response = yield proc.callApi(action.customerId || customerId, page, pageSize, filters, sorting);
    
    yield proc.set(response.data.entities);
    yield proc.setTotalPages(response.data.totalPages);
    yield proc.makeActive(action.id);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}

- OR -

function* loadOrdersSaga(action) {
  const proc = new Process.Load(ItemTypes.Order, RestApi.GetOrders, { pageble: true });
  yield proc.start();
  try {
    const customerId = yield proc.itemParam("customerId");

    yield proc.callApi(action.customerId || customerId);
    
    yield proc.setEntities();
    yield proc.setTotalPages();
    yield proc.makeActive(action.id);
    yield proc.stop();
  } catch (ex) {
    yield proc.fail(ex);
  }
}
*/

const procCode = (itemType: string, method: string): string =>
  `${itemType}_${method}`.toUpperCase();

export type TProcess = typeof Process;

export class Process {
  public static Load: TProcess;
  public static Add: TProcess;
  public static Update: TProcess;
  public static Delete: TProcess;
  public static Import: TProcess;
  public static Export: TProcess;

  public method: string;
  public code: string;
  public itemType: string;
  public apiMethod: any;
  public options: any;
  public token: any;
  public request: any;
  public response: any;
  public data: any;

  constructor(
    method: string,
    itemType?: string,
    apiMethod?: any,
    options?: any
  ) {
    this.method = method;
    this.code = itemType ? procCode(itemType, method) : method;
    this.itemType = itemType || method;
    this.apiMethod = apiMethod;
    this.options = options || {};
    this.token = null;
    this.request = null;
    this.response = null;
    this.data = null;
  }

  session = getSessionData;
  filters = () => select(Selectors.filters(this.itemType));
  sorting = () => select(Selectors.sorting(this.itemType));
  page = () =>
    select((state: any) => Selectors.getCurrentPage(state, this.itemType) || 0);
  pageSize = () =>
    select(
      (state: any) =>
        Selectors.getPageSize(state, this.itemType) || DEFAULT_PAGE_SIZE
    );
  itemParam = (param: any) =>
    select(
      (state: any) => Selectors.getItemData(state, this.itemType, param) || null
    );

  appParam = (param: any) =>
    select(
      (state: any) =>
        Selectors.getItemData(state, ItemTypeApplication, param) || null
    );

  start = () => put(start(this.code));
  reset = () => put(reset(this.code));
  stop = (description: string) => put(stop(this.code, description));

  *fail(error: any) {
    yield put(fail(this.code, error));
  }

  *callApi(...args: any[]) {
    if (!this.apiMethod)
      throw Error(`"Missing apiMethod in Process.callApi (${this.code})`);

    if (!this.token) {
      const session: { idToken: string } = yield call(this.session);
      this.token = (session && session.idToken) || null;
    }
    if (this.options.pageble) {
      const page: number = yield this.page();
      const pageSize: number = yield this.pageSize();
      const filters: object = yield this.filters();
      let sorting: any[] = yield this.sorting();
      if (!(sorting && sorting.length > 0) && this.options.defaultSorting)
        sorting = this.options.defaultSorting;
      args.push(page + 1, pageSize, filters, sorting);
    }

    if (isEditAction(this.method)) globalAny.currentAction = "edit";
    if (process.env.NODE_ENV === "test" && this.options?.mockResponse) {
      const response: object = yield call(
        this.options.mockResponse,
        this,
        args
      );
      this.response = response;
    } else {
      const response: object = yield call(this.apiMethod, this.token, ...args);
      this.response = response;
    }
    if (isEditAction(this.method)) globalAny.currentAction = null;

    if (this.response && this.response.data) this.data = this.response.data;
    return this.response;
  }

  makeActive = (id: string | number) =>
    put(DckActionCreators.itemMakeActive(this.itemType, Number(id)));

  set = (data: any, id?: string | number) => {
    if (!data) return;
    if (Number(id) > 0) {
      return put(DckActionCreators.itemSet(this.itemType, Number(id), data));
    } else {
      if (!Array.isArray(data)) data = [data];
      return put(DckActionCreators.itemsSet(this.itemType, data));
    }
  };

  setItemData = (field: string, data: any) =>
    put(ActionCreators.setItemData(this.itemType, field, data));

  setPageTitle = (title: any) => put(ActionCreators.setPageTitle({ title }));

  setEntities = () => this.set(this.data && this.data.entities);

  *setHasNext(hasNext: boolean) {
    yield put(
      ActionCreators.setHasNextPage(this.itemType, hasNext ?? this.data.hasNext)
    );
  }

  setTotalEntities = (totalEntities: any) => {
    if (typeof totalEntities === "undefined")
      totalEntities =
        this.data && this.data.totalElements ? this.data.totalElements : 0;
    return put(ActionCreators.setTotalEntities(this.itemType, totalEntities));
  };

  *setTotalPages(totalPages: any) {
    if (typeof totalPages === "undefined")
      totalPages =
        this.data && this.data.totalElements ? this.data.totalPages : 0;

    let currentPage: number = yield this.page();

    // set current page to last page if current page is greater than total pages
    if (totalPages === 0) {
      currentPage = 0;
    } else {
      if (currentPage >= totalPages) currentPage = totalPages - 1;
    }
    yield put(ActionCreators.setCurrentPage(this.itemType, currentPage));
    yield put(ActionCreators.setTotalPages(this.itemType, totalPages));
  }

  limitTotalPages = (pageSize: any, maxElements: any) => {
    if (!this.data || !pageSize) return 0;
    const maxPages = Math.floor(maxElements / pageSize);
    let totalPages = this.data.totalElements ? this.data.totalPages : 0;
    if (totalPages > maxPages) totalPages = maxPages;
    return totalPages;
  };

  buildRequest = (params: any) => {
    this.request = buildSearchRequest(params);
    return this.request;
  };
}

class ProcessLoad extends Process {
  public static for = (itemType: string): string => procCode(itemType, "load");
  constructor(itemType: string, apiMethod?: any, options?: any) {
    super("load", itemType, apiMethod, options);
  }
}
class ProcessAdd extends Process {
  public static for = (itemType: string): string => procCode(itemType, "add");
  constructor(itemType: string, apiMethod?: any, options?: any) {
    super("add", itemType, apiMethod, options);
  }
}

class ProcessUpdate extends Process {
  public static for = (itemType: string): string =>
    procCode(itemType, "update");
  constructor(itemType: string, apiMethod?: any, options?: any) {
    super("update", itemType, apiMethod, options);
  }
}

class ProcessDelete extends Process {
  public static for = (itemType: string): string =>
    procCode(itemType, "remove");
  constructor(itemType: string, apiMethod?: any, options?: any) {
    super("remove", itemType, apiMethod, options);
  }
}

class ProcessImport extends Process {
  public static for = (itemType: string): string =>
    procCode(itemType, "import");
  constructor(itemType: string, apiMethod?: any, options?: any) {
    super("import", itemType, apiMethod, options);
  }
  loadImportStatus = (options: any) =>
    put(DckActionCreators.itemsLoad(`${this.itemType}ImportStatus`, options));
}

class ProcessExport extends Process {
  public static for = (itemType: string): string =>
    procCode(itemType, "export");
  constructor(itemType: string, apiMethod?: any, options?: any) {
    super("export", itemType, apiMethod, options);
  }
}

Process.Load = ProcessLoad;
Process.Add = ProcessAdd;
Process.Update = ProcessUpdate;
Process.Delete = ProcessDelete;
Process.Import = ProcessImport;
Process.Export = ProcessExport;
