import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as V from "yup";

import { DckSelectors, DckActionCreators, DckActionTypes } from "../../redux";
import AuthExternalForm from "./AuthExternalForm";
import AuthForm from "./AuthForm";
import { styles } from "./styles";

class SignInComponent extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    signInProcessRunning: PropTypes.any,
    signInProcessFailed: PropTypes.any,
    signInProcessFailedReason: PropTypes.any,
    signInProcessError: PropTypes.any,
    resetProcessSignIn: PropTypes.any,
    signIn: PropTypes.any
  };

  componentDidMount() {
    this.props.resetProcessSignIn();
  }

  filterMessage(msg) {
    return /(user .*does not exist)|(incorrect username or password)/i.test(msg)
      ? "Incorrect email address or password."
      : msg;
  }

  render() {
    const {
      signInProcessRunning,
      signInProcessFailed,
      signInProcessFailedReason,
      signInProcessError,
      signIn
    } = this.props;

    const schema = V.object({
      email: V.string("Enter your email")
        .label("Email")
        .email()
        .required()
        .max(128),
      password: V.string("").required("Please enter password")
    });

    const initValues = {
      email: "",
      password: ""
    };

    const fields = {
      email: {
        label: "Email address",
        type: "email"
      },
      password: {
        label: "Password",
        type: "password"
      }
    };

    return (
      <AuthExternalForm
        loading={signInProcessRunning}
        failed={signInProcessFailed}
        errorMessage={
          this.filterMessage(signInProcessError) ||
          "" + this.filterMessage(signInProcessFailedReason) ||
          ""
        }
      >
        <Formik
          validationSchema={schema}
          initialValues={initValues}
          onSubmit={values => signIn(values.email, values.password)}
        >
          {props => (
            <AuthForm
              {...props}
              loadingProcess={signInProcessRunning}
              fields={fields}
              buttonText="Sign in"
              link="/forgot-password"
              linkText="Reset my password"
            />
          )}
        </Formik>
      </AuthExternalForm>
    );
  }
}

const mapStateToProps = state => {
  const signInProcess =
    DckSelectors.selectProcess(state, DckActionTypes.SIGN_IN) || {};

  const mapping = {
    signInProcessRunning: DckSelectors.selectProcessRunning(
      state,
      DckActionTypes.SIGN_IN
    ),
    signInProcessFailed: DckSelectors.selectProcessFailed(
      state,
      DckActionTypes.SIGN_IN
    ),
    signInProcessFailedReason: signInProcess.result
      ? signInProcess.result.message
      : null,
    signInProcessError: signInProcess.result ? signInProcess.result.error : null
  };

  return mapping;
};

const mapDispatchToProps = {
  signIn: (email, password) => DckActionCreators.signIn(email, password),
  resetProcessSignIn: () =>
    DckActionCreators.asyncProcessReset(DckActionTypes.SIGN_IN)
};

export const SignIn = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignInComponent));
