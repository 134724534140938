import FileSaver from "file-saver";

import { ApiCallException } from "../exceptions/ApiCallException";
import { ResponseTimeException } from "../exceptions/ResponseTimeException";
import { sleep } from "../utils";
import { captureException } from "../utils/sentry";

const MAX_CONNECT_RETRIES = 5;

const THRESHOLD_RESPONSE_TIME = 3000;

const logResponseTime = (url, params, response, responseTime) => {
  const { headers } = response;
  const { method, body } = params;
  const extras = {
    "session-id": headers.get("x-session-id"),
    "api-version": headers.get("x-version"),
    "api-call": {
      api: url,
      method,
      body: body ? JSON.stringify(body, null, 2) : null,
      "response-time": responseTime
    }
  };
  const message = `Endpoint takes a very long time (${responseTime}ms) to respond: ${url}`;
  captureException(new ResponseTimeException(message), "response-time", {
    extras
  });
};

const exponentialFetch = async (url, params) => {
  let response = null;
  let retries = 0;
  while (retries <= MAX_CONNECT_RETRIES) {
    response = null;
    try {
      if (retries) {
        const delay = 2 ** retries * 100;
        console.warn(
          `Connection error occurred. Waiting for ${delay} ms before retrying...`
        );
        await sleep(delay);
      }
      const startTime = Date.now();
      response = await fetch(url, params);
      const endTime = Date.now();
      const responseTime = endTime - startTime;
      if (responseTime > THRESHOLD_RESPONSE_TIME) {
        logResponseTime(url, params, response, responseTime);
      }
      if (response.status < 600 && response.status >= 500)
        throw Error("Server is temporarily unavailable");
      break;
    } catch {
      retries++;
    }
  }
  if (!response)
    throw new Error("NetworkError when attempting to fetch resource");
  return response;
};

export async function ApiCall(
  url,
  token,
  body,
  method = "POST",
  customHeaders,
  format = "json"
) {
  const params = {
    method
  };

  if (method === "UPLOAD") {
    params.method = "PUT";
    params.headers = customHeaders;
    params.body = body;
  } else {
    const headers = {
      ...customHeaders,
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    if (token) {
      headers["Authorization"] = `${token}`;
    }
    params.headers = headers;
    if (body) {
      params.body = typeof body === "string" ? body : JSON.stringify(body);
    }
  }

  //FIXME: SRX-11279: Need to send current acl and action to backend. This helps to detect all endpoints with different permissions on frontend and backend
  if (global.currentAcl && !String(url).includes("iothub-api")) {
    const feature = Object.keys(global.currentAcl)[0];
    const action = global.currentAction || Object.values(global.currentAcl)[0];
    process.env.REACT_APP_ACL_LOGGER &&
      console.info("URL: ", url, "\nACL: ", feature, " + ", action);
    params.headers["feature"] = feature;
    params.headers["action"] = action;
  }

  window.__api_call = { url, method, body: JSON.stringify(body, null, 2) };

  const response = await exponentialFetch(url, params);

  const { headers, status } = response;

  const sessionId = headers.get("x-session-id");
  const version = headers.get("x-version");

  window.__session_id = sessionId;
  window.__api_version = version;

  if (status >= 400 && status < 600 && status !== 409) {
    const body = await response[format]();
    console.warn(`Response error ${status}: ${body.error}`);
    const message = body.error.startsWith("JSON parse error")
      ? "We are sorry, but a response parse error has occurred."
      : status === 502 || status === 504
      ? "Server is temporarily unavailable, please try again later."
      : body.error;
    throw new ApiCallException(message, sessionId, body);
  }

  if (method === "UPLOAD") return response;

  const result = await response[format]();

  if (process.env.REACT_APP_API_MOCK_COLLECT) {
    // DEBUG: collect api calls for mocking
    if (method === "GET") {
      if (!window.__api_mock) window.__api_mock = {};
      window.__api_mock[url] = result;
    }
  }

  return result;
}

export async function getBuildVersion() {
  let version = "";
  try {
    const response = await fetch(`/version.json?${Date.now()}`);
    try {
      version = await response.json();
    } catch (e) {
      version = "";
    }
    version = /^\d+\.\d+\.\d+\.\d+$/.test(version) ? version : "";
  } catch (e) {
    version = "";
  }
  return version;
}

export const filtersAsURLParams = filters => {
  if (!filters) return "";
  return Object.keys(filters).reduce((params, name) => {
    const uri = filters[name].map(encodeURIComponent).join(",");
    return uri ? params + `&${name.replace(/-/g, ".")}=${uri}` : params;
  }, "");
};

export const sortingAsURLParams = sorting => {
  if (!sorting || sorting.length === 0) return "";
  const sort = sorting.map(field =>
    typeof field === "object" ? `${field.name},${field.order}` : field
  );
  return `&sort=${sort.join("&sort=").replace(/-/g, ".")}`;
};

export function ApiPost(url, token, body) {
  return ApiCall(url, token, body, "POST");
}

export function ApiGet(url, token, format) {
  return ApiCall(url, token, null, "GET", null, format);
}

export function ApiPut(url, token, body, headers) {
  return ApiCall(url, token, body, "PUT", headers);
}

export function ApiUpload(url, token, body, headers) {
  return ApiCall(url, token, body, "UPLOAD", headers);
}

export function ApiDelete(url, token, body) {
  return ApiCall(url, token, body, "DELETE");
}

export const downloadFileFromServer = path => {
  const fileName = path.split("/")[path.split("/").length - 1];
  fetch(`${window.location.protocol}//${window.location.host}${path}`)
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      FileSaver.saveAs(blob, fileName);
    })
    .catch(err => console.error(err));
};

export const downloadFileFromRemoteServer = (url, fileName) => {
  return fetch(url)
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      FileSaver.saveAs(blob, fileName);
    })
    .catch(err => console.error(err));
};
