import { formatToTimeZone } from "date-fns-timezone";
import * as deepmerge from "deepmerge";
import queryString from "query-string";

export const reListOfEmails =
  /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))(([,]\s*((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))))*)$|^\s{0}$/;

const arrayMerge = (dest, src) => src; // do not merge arrays, replace dest with src

export const deepMerge = (dest, src) =>
  deepmerge.default(dest, src, { arrayMerge });

export const stub = () => {};

export const isMobile = el => {
  const mobileWidths = ["xs", "sm"];
  let width = "";
  if (el && typeof el === "object") {
    const props = el.props;
    if (props && typeof props === "object") {
      width = String(props.width);
    }
  } else if (typeof el === "string") width = el;
  return mobileWidths.includes(width);
};

export const notEmpty = value =>
  Boolean(
    typeof value !== "undefined" &&
      value !== null &&
      String(value).trim() !== "" &&
      !isEmptyObject(value) &&
      !(Array.isArray(value) && value.length === 0)
  );

export const isObject = obj =>
  Boolean(obj) && typeof obj === "object" && obj.constructor === Object;

export const isNullable = value => value === null || value === undefined;

export const isEmptyObject = obj =>
  isObject(obj) && Object.keys(obj).length === 0;

export const getIdsFromObject = obj => {
  if (!isObject(obj)) return [];
  const ids = Object.keys(obj)
    .sort((a, b) => a - b)
    .map(Number);
  return ids;
};

export const isEmptyFilters = (filters, accessors) => {
  if (!filters || !accessors) return true;
  const isEmpty = accessor => !filters[accessor] || !filters[accessor][0];
  return Array.isArray(accessors)
    ? accessors.every(isEmpty)
    : isEmpty(accessors);
};

export const makeSequence = size => {
  if (!size || !Number(size)) return [];
  return [...Array(Number(size))].map((_, index) => index + 1);
};

export const cellStringify = (cell, options) => {
  if (!isObject(cell) || !cell.indexes || !cell.indexes.length) return "";

  if (options && options.format === "list") {
    return cell.indexes.toString();
  }

  if (cell.indexes.length > 1) {
    const sorted = cell.indexes.sort((a, b) => a - b);
    return `${sorted[0]} - ${sorted[sorted.length - 1]}`;
  } else {
    return cell.indexes[0];
  }
};

export const fillMap = (size, value) => ({
  ...[...new Array(size).keys()].map(key => ({ [key]: value }))
});

export const isFilledMap = map =>
  Boolean(Object.keys(map).reduce((filled, key) => filled && map[key], true));

export function removeNulls(obj) {
  function _removeNulls(obj) {
    // eslint-disable-next-line no-unused-vars
    for (let prop in obj) {
      if (isNullable(obj[prop])) delete obj[prop];
      else if (isObject(obj[prop])) _removeNulls(obj[prop]);
    }
  }
  if (!isObject(obj)) return {};
  const _obj = deepMerge({}, obj || {});
  _removeNulls(_obj);
  return _obj;
}

export const setDefaults = data => {
  function _setDefaults(obj) {
    if (obj && obj.useDefault) {
      obj.settings = deepMerge({}, obj.defaultSettings);
    }
    return obj;
  }

  data = _setDefaults(removeNulls(data));

  Object.keys(data).forEach(key => {
    if (!["useDefault", "settings", "defaultSettings"].includes(key))
      data[key] = _setDefaults(data[key]);
  });

  return data;
};

const timeValue = (h, min) =>
  `${String(h).padStart(2, "0")}:${String(min || 0).padStart(2, "0")}`;

export const getTimes12 = suffix => {
  const pm = suffix === "PM" ? 12 : 0;
  const times = [];
  for (let h = 0; h < 12; h++) {
    times.push({
      label: `${h || 12}:00 ${suffix}`,
      value: timeValue(h + pm)
    });
    times.push({
      label: `${h || 12}:30 ${suffix}`,
      value: timeValue(h + pm, 30)
    });
  }
  return times;
};

export const getTimes24 = () => {
  const times = [
    { label: "0 min", value: timeValue(0) },
    { label: "30 min", value: timeValue(0, 30) },
    { label: "1 hour", value: timeValue(1) },
    { label: "1 hour 30 min", value: timeValue(1, 30) }
  ];
  for (let h = 2; h < 24; h++) {
    times.push({ label: `${h} hours`, value: timeValue(h) });
    times.push({ label: `${h} hours 30 min`, value: timeValue(h, 30) });
  }
  return times;
};

export const toBoolean = value =>
  typeof value === "boolean" ? value : value === "true";

export const getQueryParamValue = param => {
  const queryParams = queryString.parse(window.location.search);
  return queryParams[param];
};

export const openUrlForDownload = url => {
  const doc = global.document;
  const a = doc.createElement("a");
  a.href = url;
  doc.body.appendChild(a);
  a.click();
  doc.body.removeChild(a);
};

export const asUTC = (date, format = "YYYY-MM-DD[T]HH:mm:ss") =>
  formatToTimeZone(new Date(date), format, {
    timeZone: "Etc/UTC"
  });

export const isValidInteger = value =>
  Number.isInteger(Number(value)) && !value.match(/\./);

export const getTimezones = timezone => {
  const tz = timezone.reduce(
    (timezones, value) => [
      ...timezones,
      {
        name: `${value} (${formatToTimeZone(new Date(), "Z", {
          timeZone: value
        })})`,
        label: `${value} (${formatToTimeZone(new Date(), "Z", {
          timeZone: value
        })})`,
        value
      }
    ],
    []
  );
  return tz;
};

export class RingBuffer {
  constructor(size) {
    this.size = size;
    this.buffer = new Array(this.size);
    this.reset();
  }

  reset = () => {
    this.length = 0;
    this.top = 0;
  };

  getLength = () => this.length;

  isEmpty = () => this.length === 0;
  isFull = () => this.length === this.size;

  next = () => (this.top === this.size - 1 ? 0 : this.top + 1);
  prev = () => (this.top === 0 ? this.size - 1 : this.top - 1);

  push = data => {
    const top = this.next();
    this.buffer[top] = data;
    this.top = top;
    if (!this.isFull()) this.length++;
  };

  pop = () => {
    const data = this.getTop();
    if (!data) return void 0;
    this.top = this.prev();
    this.length--;
    return data;
  };

  getTop = () => (this.isEmpty() ? void 0 : this.buffer[this.top]);
}

export const getImportedRecordsCount = error => {
  const errorString = error && error.description ? error.description : error;
  const found = (errorString || "").match(/Successfully imported (\d+)/);
  return Number((found && found[1]) || 0);
};

export const splitFromTo = value => {
  let from, to;
  if (value) {
    [from, to] = value.split(",").map(Number);
  }
  return { from, to };
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const divideArrayOnParts = (array, countOfParts) => {
  const dividedArray = [];
  const itemsPerPart = Math.ceil(array.length / countOfParts);
  for (let part = 0; part < countOfParts; part++) {
    if (!dividedArray[part]) dividedArray[part] = [];
    for (let item = 0; item < itemsPerPart; item++) {
      const value = array[item + part * itemsPerPart];
      value && dividedArray[part].push(value);
    }
  }
  return dividedArray;
};

export const isValidColor = str => /^#[0-9A-Fa-f]{6}$/.test(str);

export const getAllowedEntries = (acls, entries) =>
  entries.filter(
    entry =>
      !entry.acls ||
      Object.entries(entry.acls).some(([name, action]) =>
        Boolean(acls && acls[`${name}.${action.toLowerCase()}`])
      )
  );

export const getTransparentColor = (color, opacity) => {
  const alpha = opacity < 0 && opacity > 1 ? 1 : opacity;
  if (String(color).startsWith("rgb")) {
    const [r, g, b] = color.match(/\d+/g);
    return `rgb(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    const hex = Math.round(opacity * 255)
      .toString(16)
      .toUpperCase()
      .padStart(2, "0");
    return `${color}${hex}`;
  }
};

export const readFile = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const getBase64EncodeFileData = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split("base64,")[1]);
    reader.onerror = error => reject(error);
  });

export const parseVideoField = data => {
  if (!data?.fields) return null;
  const { title, type, video, link } = data.fields;
  const url =
    type === "VIDEO" ? video?.fields.file.url : type === "LINK" ? link : "";
  return url ? { title, url } : null;
};

export const formatLocalPrice = (value, country) => {
  const isUSA = country === "USA";
  const options = {
    style: "currency",
    currency: isUSA ? "USD" : "CAD",
    currencyDisplay: isUSA ? "symbol" : "code"
  };

  return (value || 0).toLocaleString("en-US", options);
};

export const generateUniqString = (customAlphabet = "1234567890", size = 8) => {
  let result = "";
  for (let i = 0; i < size; i++) {
    result += customAlphabet.charAt(
      Math.floor(Math.random() * customAlphabet.length)
    );
  }
  return result;
};

export function ctrlKeyLabel() {
  const navigator = window?.navigator;

  const platform =
    navigator?.userAgentData?.platform || navigator?.platform || "";

  return platform.toUpperCase().includes("MAC") ? "Cmd" : "Ctrl";
}

export const getReportType = reportType =>
  reportType === "VMI Analysis report"
    ? "VMI_ANALYSIS_REPORT"
    : "TRANSACTION_REPORT";
