import { ActionCreators } from "../actions";
import { ApiCallException } from "../exceptions/ApiCallException";
import { NotificationCreators } from "../utils/notifications";
import { captureException, isNotFiltering, setPagePath } from "../utils/sentry";

export const processMiddleware = () => {
  return ({ dispatch }) =>
    next =>
    action => {
      const { type, result = {} } = action;
      const { error } = result;

      if (type === "ASYNC_PROCESS_START") {
        dispatch(ActionCreators.checkBuildVersion());
        dispatch(ActionCreators.showLoadingIndicator());
      } else if (type === "@@router/LOCATION_CHANGE") {
        dispatch(ActionCreators.checkBuildVersion());
        dispatch(ActionCreators.resetLoadingIndicator());
        setPagePath(action.payload);
      } else if (type === "ASYNC_PROCESS_STOP") {
        setTimeout(() => dispatch(ActionCreators.hideLoadingIndicator()), 100);
        setTimeout(
          () => dispatch(ActionCreators.resetLoadingIndicator()),
          3000
        );
        if (error) {
          let notification = null;
          if (error instanceof ApiCallException) {
            if (isNotFiltering(error.message)) {
              captureException(error, "api-call");
            }
            notification = NotificationCreators.errorApiCall(error);
          } else {
            if (isNotFiltering(error)) {
              captureException(new Error(error), "api-call");
            }
            notification = NotificationCreators.errorMessage(
              error.message || error
            );
          }
          dispatch(ActionCreators.pushNotification(notification));
        }
      }
      return next(action);
    };
};
