import { getTimezones, getTimes12 } from "./utils";

export const SIZE_PER_PAGE_LIST = [10, 25, 50, 100];
export const DEFAULT_PAGE_SIZE = 50;
export const MAX_PAGE_SIZE = 100;
export const NO_DATA_TEXT = "No data found";
export const BASE_API_URL = stage => `https://api-${stage}.storeroomlogix.com`;

export const PATH_TO_ASSETS = `${process.env.PUBLIC_URL}/assets`;

export const ItemTypeApplication = "Application"; // app level item type

export const DISTRIBUTOR_GROUPS = [
  "srx-custom-distributor-users",
  "srx-distributor-admins",
  "srx-distributor-users"
];

export const CUSTOMER_GROUPS = [
  "srx-customer-super-users",
  "srx-customer-users",
  "srx-custom-customer-user"
];

export const PORTALS = {
  AUTH: {
    path: "/auth",
    port: 3000
  },
  DISTRIBUTOR: {
    path: "/distributor/marketing",
    port: 3020
  },
  CUSTOMER: {
    path: "/customer/marketing",
    port: 3030
  }
};

export const SRX_PORTAL = {
  ADMIN: "ADMIN",
  DISTRIBUTOR: "DISTRIBUTOR",
  CUSTOMER: "CUSTOMER",
  AUTH: "AUTH"
};

export const SRX_PORTAL_TITLE = {
  ADMIN: "Admin portal",
  DISTRIBUTOR: "Distributor portal",
  CUSTOMER: "Customer portal",
  AUTH: "Auth portal"
};

export const HARDWARE_STATUS = {
  GREEN: "GREEN",
  RED: "RED",
  GREY: "GREY",
  ORANGE: "ORANGE"
};

export const USA_TIMEZONES = [
  "America/Adak",
  "America/Anchorage",
  "America/Atka",
  "America/Boise",
  "America/Chicago",
  "America/Denver",
  "America/Detroit",
  "America/Fort_Wayne",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Knox_IN",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Menominee",
  "America/Metlakatla",
  "America/New_York",
  "America/Nome",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Phoenix",
  "America/Shiprock",
  "America/Sitka",
  "America/Yakutat",
  "Navajo",
  "Pacific/Honolulu",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific"
];

export const CANADA_TIMEZONES = [
  "America/Atikokan",
  "America/Blanc-Sablon",
  "America/Cambridge_Bay",
  "America/Coral_Harbour",
  "America/Creston",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Edmonton",
  "America/Fort_Nelson",
  "America/Glace_Bay",
  "America/Goose_Bay",
  "America/Halifax",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Moncton",
  "America/Montreal",
  "America/Nipigon",
  "America/Pangnirtung",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Resolute",
  "America/St_Johns",
  "America/Swift_Current",
  "America/Thunder_Bay",
  "America/Toronto",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yellowknife",
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon"
];

export const DAYS = [
  { name: "Monday", label: "Monday", value: "MONDAY" },
  { name: "Tuesday", label: "Tuesday", value: "TUESDAY" },
  { name: "Wednesday", label: "Wednesday", value: "WEDNESDAY" },
  { name: "Thursday", label: "Thursday", value: "THURSDAY" },
  { name: "Friday", label: "Friday", value: "FRIDAY" },
  { name: "Saturday", label: "Saturday", value: "SATURDAY" },
  { name: "Sunday", label: "Sunday", value: "SUNDAY" }
];

export const TIME_EVERY_OPTIONS = [...getTimes12("AM"), ...getTimes12("PM")];

export const TIMEZONES_USA = getTimezones(USA_TIMEZONES);

export const TIMEZONES_CANADA = getTimezones(CANADA_TIMEZONES);

export const MAX_QUANT_LABELS = 300;
