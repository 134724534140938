import Amplify, { Auth, PubSub } from "aws-amplify";
import { push } from "redux-first-history";
import { takeEvery, all, put, call } from "redux-saga/effects";

import { ActionTypes, ActionCreators } from "../actions";
import { ApiGet, getBuildVersion } from "../api";
import {
  ItemTypeApplication,
  BASE_API_URL,
  DISTRIBUTOR_GROUPS,
  CUSTOMER_GROUPS,
  SRX_PORTAL,
  PORTALS
} from "../config";
import { mockInitSession } from "../mocks";
import { Process } from "../processes";
import { DckActionTypes } from "../redux";
import { sleep } from "../utils";
import { AuthStorage } from "../utils/auth-storage";
import { initializeAmplify } from "../utils/initializeAmplify";
import { captureException, setUser } from "../utils/sentry";

//Required for correct registration of the PubSub module by Amplify
Amplify.PubSub = PubSub;

const CLIENT_APP_ID = process.env.REACT_APP_COGNITO_CLIENT_APP_ID;
const STAGE = process.env.REACT_APP_STAGE || "dev";
const MAX_SIGN_IN_RETRIES = 3;

const storage = new AuthStorage({
  domain: window.location.hostname,
  clientId: CLIENT_APP_ID
});

let currentUser = null;
let userData = null;

export const authCurrentSession = async () => {
  const session = await Auth.currentSession();
  return {
    idToken: session.idToken.jwtToken,
    accessToken: session.accessToken.jwtToken,
    refreshToken: session.refreshToken.token,
    payload: session.idToken.payload
  };
};

const authSignIn = async (username, password) => {
  let exception = null;
  let retry = 0;
  while (retry <= MAX_SIGN_IN_RETRIES) {
    try {
      if (retry) {
        const delay = 2 ** retry * 100;
        console.warn(
          `Connection error occurred. Waiting for ${delay} ms before retrying...`
        );
        await sleep(delay);
      }
      return await Auth.signIn(username, password);
    } catch (ex) {
      exception = ex;
      if (ex.message !== "Internal server error.") break;
      retry++;
      captureException(ex, "sign-in-issue");
    }
  }
  throw exception;
};

const authSignOut = async () => Auth.signOut();

const authForgotPassword = async user => Auth.forgotPassword(user);

const authConfirmForgotPassword = async (user, code, password) =>
  Auth.forgotPasswordSubmit(user, code, password);

const authForceChangePassword = async password =>
  Auth.completeNewPassword(currentUser, password);

const redirectTo = ({ path, port }) => {
  const url = new URL(path, window.location.origin);
  if (process.env.NODE_ENV === "development") {
    url.port = port;
  }
  window.location.replace(url.href);
};

export const getSessionData = function* () {
  if (process.env.NODE_ENV === "test") {
    const { mockSession } = yield mockInitSession();
    return mockSession;
  }

  try {
    const session = yield authCurrentSession();
    if (!userData) {
      userData = storage?.getUserData();
      setUser({ username: userData.email });
      yield put(
        ActionCreators.setItemData(ItemTypeApplication, "user", userData)
      );
    }
    return session;
  } catch (error) {
    let message = error.message || error;
    if (error.code === "UserNotFoundException") {
      message = "User not found.";
    } else if (error.code === "NetworkError") {
      message =
        "A network error has occurred, please check your internet connection and try again.";
    }
    console.warn(message);
    yield signOutSaga();
    throw new Error(message);
  }
};

const signInSaga = function* (action) {
  const proc = new Process(DckActionTypes.SIGN_IN);
  yield proc.start();
  try {
    storage && storage.clearClientCookies();
    currentUser = yield authSignIn(action.email.toLowerCase(), action.password);
    currentUser.challengeName === "NEW_PASSWORD_REQUIRED"
      ? yield put(push("/change-password"))
      : yield redirectSaga();
    yield proc.stop();
  } catch (error) {
    if (error.message.includes("User does not exist")) {
      error.message = `PreAuthentication failed with error User (${action.email.toLowerCase()}) does not exist`;
    }
    setUser({ username: action.email });
    yield proc.fail(error);
  }
};

function* forgotPasswordSaga(action) {
  const proc = new Process(DckActionTypes.RESET_PASSWORD);
  yield proc.start();
  try {
    yield authForgotPassword(action.email);
    yield proc.stop();
    yield put(push(`/forgot-password/send/${action.email}`));
  } catch (error) {
    setUser({ username: action.email });
    yield proc.fail(error);
  }
}

function* confirmForgotPasswordSaga(action) {
  const proc = new Process(DckActionTypes.RESET_PASSWORD_CONFIRMATION);
  yield proc.start();
  try {
    yield authConfirmForgotPassword(
      action.email,
      action.verificationCode,
      action.password
    );
    yield proc.stop();
    yield put(push("/forgot-password/success"));
  } catch (error) {
    setUser({ username: action.email });
    yield proc.fail(error);
  }
}

function* forceChangePasswordSaga(action) {
  const proc = new Process(DckActionTypes.FORCE_CHANGE_PASSWORD);
  yield proc.start();
  try {
    yield authForceChangePassword(action.password);
    yield proc.stop();
    yield redirectSaga();
  } catch (error) {
    yield proc.fail(error);
  }
}

function* signOutSaga() {
  try {
    yield authSignOut();
  } catch (e) {
    console.warn(e);
  }
  storage && storage.clearClientCookies();
  currentUser = userData = null;
  yield put(ActionCreators.setItemData(ItemTypeApplication, "user", null));
  [SRX_PORTAL.ADMIN, SRX_PORTAL.AUTH].includes(window.SRX_PORTAL)
    ? yield put(push("/sign-in"))
    : redirectTo(PORTALS.AUTH);
}

function* ensureChangeUserPasswordIsSetSaga() {
  if (!currentUser) yield put(push("/sign-in"));
}

function* acceptEmailInviteSaga(action) {
  const proc = new Process(ActionTypes.ACCEPT_EMAIL_INVITE);
  yield proc.start();
  try {
    yield ApiGet(`${BASE_API_URL(STAGE)}/email/accept?token=${action.token}`);
    yield proc.stop();
  } catch (error) {
    yield proc.fail(error);
  }
}

function* redirectSaga() {
  if (window.SRX_PORTAL === SRX_PORTAL.ADMIN) {
    yield put(push("/"));
  } else if (window.SRX_PORTAL === SRX_PORTAL.AUTH) {
    const sessionData = yield getSessionData();
    if (sessionData?.payload) {
      const groups = sessionData.payload["cognito:groups"] || [];
      const group = groups.length && groups[0];
      if (DISTRIBUTOR_GROUPS.includes(group)) {
        redirectTo(PORTALS.DISTRIBUTOR);
      } else if (CUSTOMER_GROUPS.includes(group)) {
        redirectTo(PORTALS.CUSTOMER);
      }
    }
  }
}

function* checkRedirectSaga() {
  try {
    yield redirectSaga();
  } catch (ex) {
    console.warn(ex);
  }
}

let lastVersionCheck = 0;
const checkInterval = 60 * 1000; // 1 min

function* checkBuildVersionSaga() {
  const now = Date.now();
  if (now - lastVersionCheck < checkInterval) return;
  lastVersionCheck = now;

  try {
    const version = yield call(getBuildVersion);
    yield put(
      ActionCreators.setItemData(
        ItemTypeApplication,
        "actualBuildVersion",
        version
      )
    );
  } catch (e) {
    console.error(e);
  }
}

function* checkAuthenticated() {
  try {
    yield getSessionData();
  } catch (ex) {
    console.warn(ex);
  }
}

export function* accountSaga() {
  yield all([
    call(initializeAmplify),
    call(checkBuildVersionSaga),
    takeEvery(DckActionTypes.SIGN_IN, signInSaga),
    takeEvery(DckActionTypes.CHECK_AUTHENTICATED, checkAuthenticated),
    takeEvery(DckActionTypes.FORCE_CHANGE_PASSWORD, forceChangePasswordSaga),
    takeEvery(
      DckActionTypes.ENSURE_CHANGE_USER_PASSWORD_IS_SET,
      ensureChangeUserPasswordIsSetSaga
    ),
    takeEvery(DckActionTypes.FORGOT_PASSWORD, forgotPasswordSaga),
    takeEvery(
      DckActionTypes.CONFIRM_FORGOT_PASSWORD,
      confirmForgotPasswordSaga
    ),
    takeEvery(DckActionTypes.SIGN_OUT, signOutSaga),
    takeEvery(ActionTypes.CHECK_BUILD_VERSION, checkBuildVersionSaga),
    takeEvery(ActionTypes.CHECK_REDIRECT, checkRedirectSaga),
    takeEvery(ActionTypes.ACCEPT_EMAIL_INVITE, acceptEmailInviteSaga)
  ]);
}
