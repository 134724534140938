export const styles = theme => ({
  //AuthPageForm
  main: {
    height: "100vh",
    minHeight: "fit-content",
    display: "flex",
    backgroundColor: "#E5E5E5"
  },
  paper: {
    width: "500px",
    margin: "auto",
    boxShadow: "0 40px 100px #AFAFAF"
  },
  progress: {
    width: "100%"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px"
  },
  errorMessage: {
    color: "red",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
    "& span": {
      marginLeft: "5px"
    }
  },

  form: {
    marginTop: theme.spacing(3),
    width: "100%"
  },
  textField: {
    margin: "10px 0",
    height: "80px",
    "& input": {
      height: "30px"
    }
  },

  label: {
    color: "gray",
    fontFamily: "Lato",
    fontWeight: "normal",
    "&$focusedLabel": {
      color: "#003a70"
    }
  },
  focusedLabel: {},

  underline: {
    "&:after": {
      borderBottom: "2px solid #003a70"
    }
  },

  line: {
    height: "4px",
    backgroundColor: "#FFB81C"
  },

  link: {
    color: "#003a70",
    fontFamily: "Lato",
    float: "right",
    marginTop: theme.spacing(3)
  },

  button: {
    marginTop: theme.spacing(2),
    height: "50px",
    color: "#FFFFFF",
    backgroundColor: "#003a70",
    "&:hover": {
      backgroundColor: "#003a70"
    }
  },
  message: {
    width: "100%",
    textAlign: "center",
    "& h2": {
      color: "#003a70",
      fontSize: "20px"
    },
    "& div": {
      color: "#616161"
    }
  }
});
